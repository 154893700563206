<template>
  <div>
    <form @submit.prevent="submitCategory" class="space-y-4">
      <div>
        <label class="block text-gray-700 mb-1" for="seriesId">Цуврал сонгох</label>
        <select id="seriesId" name="seriesId" @change="handleSeasonForm" required class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
          <option selected disabled value="">Сонгох</option>
          <option v-for="series in seriesList" :key="series.id" :value="series.id">
            {{ series.title }}
          </option>
        </select>
      </div>

      <div>
        <label class="block text-gray-700 mb-1" for="title">Бүлэг нэр</label>
        <input
          id="title"
          name="title"
          type="text"
          required
          :value="seasonForm.title"
          @input="handleSeasonForm"
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Бүлэг нэр оруулна уу"
        />
      </div>

      <div>
        <label class="block text-gray-700 mb-1" for="description">Бүлэг тайлбар</label>
        <input
          id="description"
          name="description"
          type="text"
          required
          :value="seasonForm.description"
          @input="handleSeasonForm"
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Бүлэг тайлбар оруулна уу"
        />
      </div>

      <div>
        <label class="block text-gray-700 mb-1" for="seasonNumber">Бүлэг дугаар</label>
        <input
          id="seasonNumber"
          name="seasonNumber"
          type="number"
          required
          :value="seasonForm.seasonNumber"
          @input="handleSeasonForm"
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Бүлэг тайлбар оруулна уу"
        />
      </div>

      <div>
        <button type="submit" :disabled="isLoading" class="w-[250px] bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg transition duration-200 disabled:opacity-50">
          Төрөл нэмэх
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "CourseUploadForm",
  setup() {
    const store = useStore();

    const seriesList = computed(() => store.state.vod.seriesList);
    const isLoading = computed(() => store.state.vod.isLoading);
    const seasonForm = computed(() => store.state.vod.seasonForm);

    const handleSeasonForm = (e) => {
      store.commit("vod/setSeasonForm", {
        key: e.target.name,
        value: e.target.value,
      });
      console.log("season form", seasonForm.value);
    };

    const submitCategory = () => {
      store.dispatch("vod/createSeason");
    };

    // watch(
    //   () => seasonForm.value.seriesId,
    //   (seriesId) => {
    //     if (seriesId) {
    //       store.dispatch("admin/fetchChapters", seriesId);
    //     }
    //   }
    // );

    onMounted(() => {
      store.dispatch("vod/fetchSeriesList");
    });

    return {
      isLoading,
      seriesList,
      seasonForm,
      handleSeasonForm,
      submitCategory,
    };
  },
};
</script>

<style scoped>
/* Optional: Add custom styles if needed */
</style>
