<template>
  <div>
    <form @submit.prevent="submitCategory" class="space-y-4">
      <div>
        <label class="block text-gray-700 mb-1" for="categoryName">Төрөл</label>
        <input
          id="categoryName"
          name="categoryName"
          type="text"
          required
          :value="categoryName"
          @input="handleCategoryName"
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Төрөл нэр оруулна уу"
        />
      </div>

      <div>
        <label class="block text-gray-700 mb-1" for="categoryDescription">Төрөл тайлбар</label>
        <input
          id="categoryDescription"
          name="categoryDescription"
          type="text"
          required
          :value="categoryName"
          @input="handleCategoryName"
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Төрөл тайлбар оруулна уу"
        />
      </div>

      <div>
        <button type="submit" :disabled="isLoading" class="w-[250px] bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg transition duration-200 disabled:opacity-50">
          Төрөл нэмэх
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "CourseUploadForm",
  setup() {
    const store = useStore();

    const categoryName = computed(() => store.state.vod.categoryName);
    const isLoading = computed(() => store.state.vod.isLoading);

    const submitCategory = () => {
      store.dispatch("vod/createCategory", categoryName.value);
    };

    const handleCategoryName = (e) => {
      store.commit("vod/setCategoryForm", {
        key: e.target.name,
        value: e.target.value,
      });
    };

    return {
      isLoading,
      categoryName,
      handleCategoryName,
      submitCategory,
    };
  },
};
</script>

<style scoped>
/* Optional: Add custom styles if needed */
</style>
