<template>
  <div>
    <video ref="videoPlayer" :src="videoSrc" controls autoplay class="w-full h-auto" @loadeddata="onVideoLoaded"></video>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  name: "VideoPlayer",
  props: {
    videoId: {
      type: [String, Number],
      default: null,
    },
    episodeId: {
      type: [String, Number],
      default: null,
    },
  },
  setup(props, { expose }) {
    const videoPlayer = ref(null);
    const videoSrc = ref("");

    const generateVideoSrc = () => {
      let baseUrl = "";
      if (props.videoId) {
        // baseUrl = `http://localhost:3002/api/admin/video/${props.videoId}/stream`;
        baseUrl = `https://app.mnb.mn/api/admin/video/${props.videoId}/stream`;
      } else if (props.episodeId) {
        baseUrl = `https://app.mnb.mn/api/admin/episodes/${props.episodeId}/stream`;
        // baseUrl = `http://localhost:3002/api/admin/episodes/${props.episodeId}/stream`;
      } else {
        return "";
      }
      return `${baseUrl}?t=${Date.now()}`;
    };

    const resetVideo = () => {
      if (videoPlayer.value) {
        videoPlayer.value.pause();
        videoPlayer.value.currentTime = 0;
        videoPlayer.value.src = "";
        videoPlayer.value.load();
      }

      videoSrc.value = generateVideoSrc();
    };

    const onVideoLoaded = () => {
      if (videoPlayer.value) {
        videoPlayer.value.play();
      }
    };

    onMounted(() => {
      videoSrc.value = generateVideoSrc();
    });

    expose({
      resetVideo,
    });

    return {
      videoPlayer,
      videoSrc,
      onVideoLoaded,
    };
  },
};
</script>

<style scoped>
/* Add any styles if needed */
</style>
