<template>
  <div>
    <form @submit.prevent="submitEpisode" class="space-y-4">
      <div>
        <label class="block text-gray-700 mb-1" for="seriesId">Цуврал сонгох</label>
        <select id="seriesId" name="seriesId" @change="handleSeriesEpisodeForm" required class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
          <option selected disabled value="">Сонгох</option>
          <option v-for="series in seriesList" :key="series.id" :value="series.id">
            {{ series.title }}
          </option>
        </select>
      </div>

      <div v-if="seasons.length > 0">
        <label class="block text-gray-700 mb-1" for="seasonId">Бүлэг сонгох</label>
        <select id="seasonId" name="seasonId" @change="handleSeriesEpisodeForm" required class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
          <option selected disabled value="">Сонгох</option>
          <option v-for="season in seasons" :key="season.id" :value="season.id">{{ season.season_number }}-р бүлэг</option>
        </select>
      </div>

      <div>
        <label class="block text-gray-700 mb-1" for="episodeNumber">Ангийн дугаар</label>
        <input
          id="episodeNumber"
          name="episodeNumber"
          :value="seriesEpisodeForm.episodeNumber"
          @input="handleSeriesEpisodeForm"
          type="number"
          required
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Анги дугаар оруулна уу"
        />
      </div>

      <div>
        <label class="block text-gray-700 mb-1" for="title">Ангийн нэр</label>
        <input
          id="title"
          name="title"
          :value="seriesEpisodeForm.title"
          @input="handleSeriesEpisodeForm"
          type="text"
          required
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Анги нэр оруулна уу"
        />
      </div>

      <div>
        <label class="block text-gray-700 mb-1" for="description">Тайлбар</label>
        <textarea
          id="description"
          name="description"
          :value="seriesEpisodeForm.description"
          @input="handleSeriesEpisodeForm"
          required
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          rows="4"
          placeholder="Анги тайлбар оруулна уу"
        ></textarea>
      </div>

      <div>
        <label class="block text-gray-700 mb-1" for="video">Бичлэг файл</label>
        <input
          id="video"
          name="video"
          @change="handleFileChange"
          ref="fileVideoRef"
          type="file"
          accept="video/*"
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Бичлэг  зураг оруулна уу"
        />
      </div>

      <div>
        <button type="submit" :disabled="isLoading" class="w-[250px] bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg transition duration-200 disabled:opacity-50">
          {{ isLoading ? "Анги нэмэж байна..." : "Анги нэмэх" }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "CourseUploadForm",
  setup() {
    const store = useStore();
    const fileRef = ref(null);

    // Computed properties for data
    const isLoading = computed(() => store.state.vod.isLoading);
    const seriesList = computed(() => store.state.vod.seriesList);
    const seasons = computed(() => store.state.vod.seasons);
    const seriesEpisodeForm = computed(() => store.state.vod.seriesEpisodeForm);

    const handleSeriesEpisodeForm = (e) => {
      store.commit("vod/setSeriesEpisodeForm", {
        key: e.target.name,
        value: e.target.value,
      });
    };

    const handleFileChange = (e) => {
      store.commit("vod/setSeriesEpisodeForm", {
        key: e.target.name,
        value: e.target.files[0],
      });
    };

    const submitEpisode = () => {
      store.dispatch("vod/createEpisode");
    };

    watch(
      () => seriesEpisodeForm.value.seriesId,
      (seriesId) => {
        if (seriesId) {
          store.dispatch("vod/fetchSeasonsBySeriesId", seriesId);
        }
      }
    );

    onMounted(() => {
      store.dispatch("vod/fetchSeriesList");
    });

    return {
      fileRef,
      isLoading,
      seriesList,
      seasons,
      seriesEpisodeForm,
      handleSeriesEpisodeForm,
      handleFileChange,
      submitEpisode,
    };
  },
};
</script>

<style scoped>
/* Optional: Add custom styles if needed */
</style>
