import { adminInstance } from "@/lib/adminInstance";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    success: null,

    categories: [],
    seasons: [],
    seriesList: [],

    // insert states here
    categoryForm: {
      categoryName: "",
      categoryDescription: "",
    },

    videoForm: {
      type: "single",
      title: "",
      categoryId: "",
      description: "",
      price: "",
      image: "",
      video: "",
    },

    seriesEpisodeForm: {
      type: "series",
      seriesId: "",
      seasonId: null,
      title: "",
      episodeNumber: "",
      description: "",
      video: null,
    },

    seriesForm: {
      categoryId: "",
      title: "",
      content: "",
      price: null,
      image: "",
    },

    seasonForm: {
      seriesId: "",
      title: "",
      description: "",
      seasonNumber: null,
    },
  },

  getters: {
    getIsLoading: (state) => state.isLoading,
    getError: (state) => state.error,
    getSuccess: (state) => state.success,

    getCategories: (state) => state.categories,
    getInstructors: (state) => state.instructors,

    // insert getters here
    getCategoryForm: (state) => state.categoryForm,
    getVideoForm: (state) => state.videoForm,
    getSeriesEpisodeForm: (state) => state.seriesEpisodeForm,
  },
  mutations: {
    setIsLoading: (state, payload) => (state.isLoading = payload),
    setError: (state, payload) => (state.error = payload),
    setSuccess: (state, payload) => (state.success = payload),

    setCategories: (state, payload) => (state.categories = payload),
    setSeasons: (state, payload) => (state.seasons = payload),
    setSeriesList: (state, payload) => (state.seriesList = payload),

    // insert mutations here
    setCategoryForm: (state, payload) => (state.categoryForm[payload.key] = payload.value),
    setVideoForm: (state, payload) => (state.videoForm[payload.key] = payload.value),
    setSeriesEpisodeForm: (state, payload) => (state.seriesEpisodeForm[payload.key] = payload.value),
    setSeriesForm: (state, payload) => (state.seriesForm[payload.key] = payload.value),
    setSeasonForm: (state, payload) => (state.seasonForm[payload.key] = payload.value),

    updateSeriesListInState(state, updatedLesson) {
      const index = state.seriesList.findIndex((lesson) => lesson.id === updatedLesson.id);

      if (index !== -1) {
        state.seriesList.splice(index, 1, updatedLesson);
      }
    },
    removeSeriesListFromState(state, seriesId) {
      state.seriesList = state.seriesList.filter((series) => series.id !== seriesId);
    },
  },

  actions: {
    async fetchCategories({ commit }) {
      try {
        const response = await adminInstance.get("/categories");
        if (response.status === 200) {
          commit("setCategories", response.data.categories);
        }
      } catch (error) {
        console.error(error);
        commit("setError", error.message);
        setTimeout(() => {
          commit("setError", null);
        }, 5000);
      }
    },

    async fetchSeasonsBySeriesId({ commit }, seriesId) {
      try {
        const response = await adminInstance.get(`/upload/season/${seriesId}`);
        if (response.status === 200) {
          commit("setSeasons", response.data.seasons);
        }
      } catch (error) {
        console.error(error);
        commit("setError", error.message);
        setTimeout(() => {
          commit("setError", null);
        }, 5000);
      }
    },

    async fetchSeriesList({ commit }) {
      try {
        const response = await adminInstance.get("/upload/series");
        if (response.status === 200) {
          commit("setSeriesList", response.data.series);
        }
      } catch (error) {
        console.error(error);
        commit("setError", error.message);
        setTimeout(() => {
          commit("setError", null);
        }, 5000);
      }
    },

    // insert actions here
    async createCategory({ dispatch, commit, state }) {
      try {
        commit("setIsLoading", true);
        commit("setError", null);

        const response = await adminInstance.post("/upload/category", {
          name: state.categoryForm.categoryName,
          description: state.categoryForm.categoryDescription,
        });
        if (response.status === 200) {
          commit("setSuccess", "Амжилттай нэмэгдлээ");
          commit("setCategoryForm", { key: "categoryName", value: "" });
          commit("setCategoryForm", { key: "categoryDescription", value: "" });
          commit("setError", null);
          setTimeout(() => {
            commit("setSuccess", null);
          }, 5000);
          dispatch("fetchCategories");
        }
      } catch (error) {
        console.error(error);
        commit("setError", error.message);
        setTimeout(() => {
          commit("setError", null);
        }, 5000);
      } finally {
        commit("setIsLoading", false);
      }
    },

    async createVideo({ commit, state }) {
      try {
        commit("admin/setIsLoading", true, { root: true });
        commit("admin/setError", null, { root: true });

        const formData = new FormData();
        formData.append("title", state.videoForm.title);
        formData.append("categoryId", state.videoForm.categoryId);
        formData.append("description", state.videoForm.description);
        formData.append("price", state.videoForm.price);
        formData.append("type", state.videoForm.type);
        formData.append("image", state.videoForm.image);
        formData.append("video", state.videoForm.video);

        const response = await adminInstance.post("/upload/video", formData);

        if (response.status === 200) {
          commit("admin/setSuccess", "Амжилттай нэмэгдлээ", { root: true });
          commit("setVideoForm", { key: "title", value: "" });
          commit("setVideoForm", { key: "instructorId", value: "" });
          commit("setVideoForm", { key: "categoryId", value: "" });
          commit("setVideoForm", { key: "description", value: "" });
          commit("setVideoForm", { key: "price", value: "" });
          commit("setVideoForm", { key: "image", value: "" });
          commit("setVideoForm", { key: "video", value: "" });
          setTimeout(() => {
            commit("admin/setSuccess", null, { root: true });
          }, 5000);
        }
      } catch (error) {
        console.error(error);
        commit("admin/setError", error.message, { root: true });
        setTimeout(() => {
          commit("admin/setError", null, { root: true });
        }, 5000);
      } finally {
        commit("admin/setIsLoading", false, { root: true });
      }
    },

    async createSeason({ commit, state }) {
      try {
        commit("admin/setIsLoading", true, { root: true });
        commit("admin/setError", null, { root: true });
        const response = await adminInstance.post("/upload/season", {
          seriesId: state.seasonForm.seriesId,
          seasonNumber: state.seasonForm.seasonNumber,
          title: state.seasonForm.title,
          description: state.seasonForm.description,
        });

        if (response.status === 200) {
          commit("admin/setSuccess", "Амжилттай нэмэгдлээ", { root: true });
          commit("setSeasonForm", { key: "seriesId", value: "" });
          commit("setSeasonForm", { key: "seasonNumber", value: "" });
          commit("setSeasonForm", { key: "title", value: "" });
          commit("setSeasonForm", { key: "description", value: "" });

          setTimeout(() => {
            commit("admin/setSuccess", null, { root: true });
          }, 5000);
        }
      } catch (error) {
        console.error(error);
        commit("admin/setError", error.message, { root: true });
        setTimeout(() => {
          commit("admin/setError", null, { root: true });
        }, 5000);
      } finally {
        commit("admin/setIsLoading", false, { root: true });
      }
    },

    async createSeries({ commit, state }) {
      try {
        commit("admin/setIsLoading", true, { root: true });
        commit("admin/setError", null, { root: true });

        const formData = new FormData();

        formData.append("categoryId", state.seriesForm.categoryId);
        formData.append("title", state.seriesForm.title);
        formData.append("content", state.seriesForm.content);
        formData.append("price", state.seriesForm.price);
        formData.append("image", state.seriesForm.image);

        const response = await adminInstance.post("/upload/series", formData);

        if (response.status === 200) {
          commit("admin/setSuccess", "Амжилттай нэмэгдлээ", { root: true });
          commit("setSeriesForm", { key: "categoryId", value: "" });
          commit("setSeriesForm", { key: "title", value: "" });
          commit("setSeriesForm", { key: "content", value: "" });
          commit("setSeriesForm", { key: "image", value: "" });
          commit("setSeriesForm", { key: "price", value: "" });
          setTimeout(() => {
            commit("admin/setSuccess", null, { root: true });
          }, 5000);
        }
      } catch (error) {
        console.error(error);
        commit("admin/setError", error.message, { root: true });
        setTimeout(() => {
          commit("admin/setError", null, { root: true });
        }, 5000);
      } finally {
        commit("admin/setIsLoading", false, { root: true });
      }
    },

    async createEpisode({ commit, state }) {
      try {
        commit("admin/setIsLoading", true, { root: true });
        commit("admin/setError", null, { root: true });

        const formData = new FormData();
        formData.append("type", state.seriesEpisodeForm.type);
        formData.append("seriesId", state.seriesEpisodeForm.seriesId);
        formData.append("seasonId", state.seriesEpisodeForm.seasonId);
        formData.append("episodeNumber", state.seriesEpisodeForm.episodeNumber);
        formData.append("title", state.seriesEpisodeForm.title);
        formData.append("description", state.seriesEpisodeForm.description);
        formData.append("video", state.seriesEpisodeForm.video);

        const response = await adminInstance.post("/upload/episode", formData);

        if (response.status === 200) {
          commit("admin/setSuccess", "Амжилттай нэмэгдлээ", { root: true });
          commit("setSeriesEpisodeForm", { key: "seriesId", value: "" });
          commit("setSeriesEpisodeForm", { key: "seasonId", value: "" });
          commit("setSeriesEpisodeForm", { key: "episodeNumber", value: "" });
          commit("setSeriesEpisodeForm", { key: "title", value: "" });
          commit("setSeriesEpisodeForm", { key: "description", value: "" });
          commit("setSeriesEpisodeForm", { key: "video", value: null });
          setTimeout(() => {
            commit("admin/setSuccess", null, { root: true });
          }, 5000);
        }
      } catch (error) {
        console.error(error);
        commit("admin/setError", error.message, { root: true });
        setTimeout(() => {
          commit("admin/setError", null, { root: true });
        }, 5000);
      } finally {
        commit("admin/setIsLoading", false, { root: true });
      }
    },
  },
};
