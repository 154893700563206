import { createStore } from "vuex";
import admin from "./admin";
import vod from "./vod";
export default createStore({
  namespaced: true,
  modules: {
    admin,
    vod,
  },
});
