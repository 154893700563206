<template>
  <div>
    <form @submit.prevent="submitCourse" class="space-y-4">
      <div>
        <label class="block text-gray-700 mb-1" for="category">Төрөл</label>
        <select id="category" name="categoryId" @change="handleVideoForm" required class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
          <option selected disabled value="">Сонгох</option>
          <option v-for="category in categories" :key="category.id" :value="category.category_id">
            {{ category.name }}
          </option>
        </select>
      </div>

      <div>
        <label class="block text-gray-700 mb-1" for="title">Бичлэг нэр</label>
        <input
          id="title"
          name="title"
          :value="videoForm.title"
          @input="handleVideoForm"
          type="text"
          required
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Бичлэг нэр оруулна уу"
        />
      </div>

      <div>
        <label class="block text-gray-700 mb-1" for="description">Тайлбар</label>
        <textarea
          id="description"
          name="description"
          :value="videoForm.description"
          @input="handleVideoForm"
          required
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          rows="4"
          placeholder="Бичлэг тайлбар оруулна уу"
        ></textarea>
      </div>

      <div>
        <label class="block text-gray-700 mb-1" for="price">Үнэ (₮)</label>
        <input
          id="price"
          name="price"
          :value="videoForm.price"
          @input="handleVideoForm"
          type="number"
          min="0"
          required
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Бичлэг үнэ оруулна уу"
        />
      </div>

      <div>
        <label class="block text-gray-700 mb-1" for="image">Бичлэг зураг</label>
        <input
          id="image"
          name="image"
          @change="handleFileChange"
          ref="fileImageRef"
          type="file"
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Бичлэг  зураг оруулна уу"
        />
      </div>

      <div>
        <label class="block text-gray-700 mb-1" for="video">Бичлэг файл</label>
        <input
          id="video"
          name="video"
          accept="video/*"
          @change="handleFileChange"
          ref="fileVideoRef"
          type="file"
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Бичлэг  зураг оруулна уу"
        />
      </div>

      <div>
        <button type="submit" :disabled="isLoading" class="w-[250px] bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg transition duration-200 disabled:opacity-50">
          {{ isLoading ? "Бичлэг нэмэж байна..." : "Бичлэг нэмэх" }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "CourseUploadForm",
  setup() {
    const store = useStore();
    const fileImageRef = ref(null);
    const fileVideoRef = ref(null);

    // Computed properties for data
    const isLoading = computed(() => store.state.vod.isLoading);
    const categories = computed(() => store.state.vod.categories);
    const videoForm = computed(() => store.state.vod.videoForm);

    const handleVideoForm = (e) => {
      store.commit("vod/setVideoForm", {
        key: e.target.name,
        value: e.target.value,
      });
    };

    const handleFileChange = (e) => {
      store.commit("vod/setVideoForm", {
        key: e.target.name,
        value: e.target.files[0],
      });
    };

    const submitCourse = () => {
      store.dispatch("vod/createVideo");
      fileImageRef.value.value = "";
      fileVideoRef.value.value = "";
    };

    return {
      fileImageRef,
      fileVideoRef,
      isLoading,
      categories,
      videoForm,
      handleVideoForm,
      handleFileChange,
      submitCourse,
    };
  },
};
</script>

<style scoped>
/* Optional: Add custom styles if needed */
</style>
