<template>
  <div class="grid grid-cols-1 md:grid-cols-9 gap-4 mb-4">
    <!-- Category Filter -->
    <div class="col-span-1 md:col-span-2">
      <label for="category" class="block mb-2 text-sm font-medium text-gray-900">Төрөл</label>
      <select
        id="category"
        name="category"
        v-model="filterValues.category"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      >
        <option disabled value="">Төрөл сонгох</option>
        <option value="1">Алтан сан хөмрөг</option>
        <option value="2">Монгол кино</option>
      </select>
    </div>

    <!-- Date Filter -->
    <div class="col-span-1 md:col-span-2">
      <label for="date" class="block mb-2 text-sm font-medium text-gray-900">Огноо</label>
      <input
        type="date"
        id="date"
        name="date"
        v-model="filterValues.date"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      />
    </div>

    <!-- Search Filter -->
    <div class="col-span-1 md:col-span-3 relative flex flex-col justify-center focus-within:text-blue-500">
      <label for="search" class="block mb-2 text-sm font-medium text-gray-900">Хайх</label>
      <input
        type="text"
        id="search"
        name="search"
        placeholder="Бичлэгийн нэр"
        v-model="filterValues.search"
        @keyup.enter="fetchVideoList"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      />
      <button type="button" class="absolute right-0 top-[40px] mr-2.5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" @click="fetchVideoList">
        <!-- SVG for Search Icon -->
      </button>
    </div>

    <!-- Reset Filters Button -->
    <div class="col-span-1 md:col-span-1 flex items-end">
      <button
        type="button"
        @click="clearFilters"
        class="w-10 h-10 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center justify-center"
      >
        <font-awesome-icon :icon="['fas', 'xmark']" />
      </button>
    </div>
  </div>

  <!-- Video List Table -->
  <div class="mt-4" v-if="!isLoading && vodList.length > 0">
    <hot-table :settings="hotSettings" :data="vodList" ref="hotRef" />
  </div>
  <div class="mt-4" v-if="!isLoading && vodList.length === 0">
    <p class="text-center text-gray-500">Бичлэг олдсонгүй</p>
  </div>

  <!-- Video Modal -->
  <div class="fixed inset-0 bg-black bg-opacity-50 z-[9998]" v-if="isVideoModalOpen" @click="isVideoModalOpen = false"></div>
  <div class="fixed inset-0 bg-black bg-opacity-50 z-[9998]" v-if="isEditModalOpen" @click.self="closeEditModal"></div>
  <div class="fixed inset-0 flex items-center justify-center z-[9999]" v-if="isEditModalOpen">
    <div class="bg-white p-6 rounded-lg w-full max-w-md mx-auto">
      <h2 class="text-xl font-semibold mb-4">Бичлэг засах</h2>
      <form @submit.prevent="updateVideo">
        <div class="mb-4">
          <label for="title" class="block text-sm font-medium text-gray-700">Гарчиг</label>
          <input type="text" id="title" v-model="editForm.title" class="mt-1 block w-full border border-gray-300 rounded-md p-2" required />
        </div>

        <div class="mb-4">
          <label for="description" class="block text-sm font-medium text-gray-700">Тайлбар</label>
          <textarea id="description" v-model="editForm.description" class="mt-1 block w-full border border-gray-300 rounded-md p-2" required></textarea>
        </div>

        <div class="mb-4">
          <label for="price" class="block text-sm font-medium text-gray-700">Үнэ (₮)</label>
          <input type="number" id="price" v-model="editForm.price" class="mt-1 block w-full border border-gray-300 rounded-md p-2" required />
        </div>

        <div class="mb-4">
          <label for="category" class="block text-sm font-medium text-gray-700">Төрөл</label>
          <select id="category" name="category" v-model="editForm.category_id" required class="mt-1 block w-full border border-gray-300 rounded-md p-2">
            <option disabled value="">Сонгох</option>
            <option v-for="category in categories" :key="category.category_id" :value="category.category_id">
              {{ category.name }}
            </option>
          </select>
        </div>

        <div class="mb-4">
          <label for="video" class="block text-sm font-medium text-gray-700">Бичлэг солих</label>
          <input type="file" id="video" ref="videoFileRef" accept="video/*" class="mt-1 block w-full" />
        </div>

        <div class="flex justify-end">
          <button type="button" @click="closeEditModal" class="mr-2 px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600">Болих</button>
          <button type="submit" class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">Хадгалах</button>
        </div>
      </form>
    </div>
  </div>

  <div
    class="absolute top-[30%] l-4 r-4 md:fixed md:top-1/2 md:left-1/2 transform md:-translate-x-1/2 md:-translate-y-1/2 overflow-hidden transition-all duration-300 ease-in-out flex flex-col items-center justify-center z-[9999]"
    v-if="isVideoModalOpen"
  >
    <video-player :videoId="videoId" />
  </div>
</template>
<script>
import { ref, onMounted, reactive, watch, computed } from "vue";
import { useStore } from "vuex";

import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";

import VideoPlayer from "@/components/VideoPlayer.vue";
import { adminInstance } from "@/lib/adminInstance";
registerAllModules();

export default {
  name: "NonSeriesList",
  components: {
    VideoPlayer,
    HotTable,
  },
  setup() {
    const store = useStore();
    const isLoading = computed(() => store.state.admin.isLoading);
    const vodList = computed(() => store.state.admin.vodList);
    const categories = computed(() => store.state.vod.categories);

    const hotRef = ref(null);
    const isVideoModalOpen = ref(false);
    const videoId = ref(null);

    const isEditModalOpen = ref(false);
    const editForm = reactive({
      video_id: null,
      title: "",
      description: "",
      price: "",
      category_id: "",
    });

    const filterValues = reactive({
      category: "",
      date: "",
      search: "",
    });

    const fetchVideoList = async () => {
      await store.dispatch("admin/fetchVideoList", filterValues);
    };

    onMounted(() => {
      fetchVideoList();
      store.dispatch("vod/fetchCategories");
    });

    watch(
      () => ({ ...filterValues }),
      () => {
        fetchVideoList();
      },
      { deep: true }
    );

    const actionRenderer = (instance, td, row) => {
      td.classList.add("htMiddle");
      const watchButtonId = `watch-button-${row}`;
      const editButtonId = `edit-button-${row}`;
      const deleteButtonId = `delete-button-${row}`;

      td.innerHTML = `<div class="flex justify-center items-center gap-2 py-1">
        <button id="${watchButtonId}" class="w-24 bg-blue-500 text-white rounded-md text-center p-1 hover:bg-blue-600 transition ease-in-out duration-150">Үзэх</button>
        <button id="${editButtonId}" class="w-24 bg-green-500 text-white rounded-md text-center p-1 hover:bg-green-600 transition ease-in-out duration-150">Засах</button>
        <button id="${deleteButtonId}" class="w-24 bg-red-500 text-white rounded-md text-center p-1 hover:bg-red-600 transition ease-in-out duration-150">Устгах</button>
      </div>`;

      const watchButton = document.getElementById(watchButtonId);
      const editButton = document.getElementById(editButtonId);
      const deleteButton = document.getElementById(deleteButtonId);
      const videoIdValue = instance.getDataAtRowProp(row, "video_id");

      if (watchButton) {
        watchButton.onclick = () => {
          isVideoModalOpen.value = true;
          videoId.value = videoIdValue;
        };
      }

      if (editButton) {
        editButton.onclick = () => {
          openEditModal(videoIdValue);
        };
      }
      if (deleteButton) {
        deleteButton.onclick = () => {
          deleteVideo(videoIdValue, row, instance);
        };
      }
    };

    const openEditModal = async (videoIdValue) => {
      try {
        const response = await adminInstance.get(`/video/${videoIdValue}`);
        if (response.status === 200) {
          const videoData = response.data.video;
          editForm.video_id = videoData.video_id;
          editForm.title = videoData.title;
          editForm.description = videoData.description;
          editForm.price = videoData.price;
          editForm.category_id = videoData.category_id;
          isEditModalOpen.value = true;
        }
      } catch (error) {
        console.error(error);
      }
    };

    const closeEditModal = () => {
      isEditModalOpen.value = false;
      editForm.video_id = null;
      editForm.title = "";
      editForm.description = "";
      editForm.price = "";
      editForm.category_id = "";
      videoFileRef.value.value = null;
    };

    const updateVideo = async () => {
      try {
        const formData = new FormData();
        formData.append("title", editForm.title);
        formData.append("description", editForm.description);
        formData.append("price", editForm.price);
        formData.append("category_id", editForm.category_id);

        console.log(videoFileRef.value);

        const videoFile = videoFileRef.value.files[0];
        if (videoFile) {
          formData.append("video", videoFile);
        }

        const response = await adminInstance.put(`/video/${editForm.video_id}`, formData);

        if (response.status === 200) {
          fetchVideoList();
          closeEditModal();
        }
      } catch (error) {
        console.error(error);
      }
    };

    const deleteVideo = async (videoIdValue, row, instance) => {
      try {
        const confirmDelete = confirm("Та энэ бичлэгийг устгахдаа итгэлтэй байна уу?");
        if (!confirmDelete) return;

        const response = await adminInstance.delete(`/video/${videoIdValue}`);

        if (response.status === 200) {
          instance.alter("remove_row", row);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const videoFileRef = ref(null);

    const hotSettings = {
      data: vodList.value,
      colWidths: [null, 200, 200, 300, 100, 200, 250, 200, 200, 200, 200, 200, 200, 200],
      colHeaders: [
        "ID",
        "Бичлэгийн нэр",
        "Бичлэгийн төрөл",
        "Тайлбар",
        "Үнэ (₮)",
        "Оруулсан файлийн нэр",
        "Хадгалагдсан файлын нэр",
        "Бичлэгийн урт (мин)",
        "Бичлэгийн файл төрөл",
        "Бичлэгийн дүрслэл",
        "Бичлэгийн хэмжээ (MB)",
        "Бичлэг оруулсан огноо",
        "Шинэчлэгдсэн огноо",
        "Үйлдэл",
      ],
      columns: [
        { data: "video_id" },
        { data: "title", readOnly: true },
        { data: "category_name", readOnly: true },
        { data: "description", readOnly: true },
        { data: "price", readOnly: true },
        { data: "original_filename", readOnly: true },
        { data: "filename", readOnly: true },
        { data: "duration", readOnly: true },
        { data: "file_type", readOnly: true },
        { data: "resolution", readOnly: true },
        { data: "file_size", readOnly: true },
        { data: "created_at", readOnly: true },
        { data: "updated_at", readOnly: true },
        {
          renderer: actionRenderer,
          readOnly: true,
        },
      ],
      hiddenColumns: {
        columns: [0],
        indicators: false,
      },
      fixedColumnsLeft: window.innerWidth < 768 ? 0 : 2,
      width: "100%",
      height: "576px",
      rowHeaders: true,
      columnSorting: true,
      manualRowResize: true,
      manualColumnResize: true,
      manualRowMove: true,
      manualColumnMove: true,
      contextMenu: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    const clearFilters = () => {
      filterValues.category = "";
      filterValues.date = "";
      filterValues.search = "";
      fetchVideoList();
    };

    return {
      isLoading,
      isVideoModalOpen,
      videoId,
      hotRef,
      hotSettings,
      categories,
      vodList,
      filterValues,
      clearFilters,
      fetchVideoList,
      isEditModalOpen,
      openEditModal,
      closeEditModal,
      editForm,
      updateVideo,
      videoFileRef,
    };
  },
};
</script>
