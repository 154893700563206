<template>
  <div>
    <form class="space-y-4">
      <div>
        <label class="block text-gray-700 mb-1" for="categoryId">Төрөл сонгох</label>
        <select
          id="categoryId"
          name="categoryId"
          @change="handleSeriesForm"
          required
          :value="seriesForm.categoryId"
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option selected disabled value="">Сонгох</option>
          <option v-for="course in categories" :key="course.course_id" :value="course.category_id">
            {{ course.name }}
          </option>
        </select>
      </div>

      <div>
        <label class="block text-gray-700 mb-1" for="title"> Цувралын нэр </label>
        <input
          id="title"
          name="title"
          :value="seriesForm.title"
          @input="handleSeriesForm"
          type="text"
          required
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Цувралын нэр оруулна уу"
        />
      </div>

      <div>
        <label class="block text-gray-700 mb-1" for="content">Цувралын агуулга</label>
        <textarea
          id="content"
          name="content"
          :value="seriesForm.content"
          @input="handleSeriesForm"
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          rows="5"
          placeholder="Цувралын агуулга оруулна уу"
        ></textarea>
      </div>

      <div>
        <label class="block text-gray-700 mb-1" for="price"> Цувралын үнэ </label>
        <input
          id="price"
          name="price"
          :value="seriesForm.price"
          @input="handleSeriesForm"
          type="text"
          required
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Цуврал нэр оруулна уу"
        />
      </div>

      <div>
        <label class="block text-gray-700 mb-1" for="image">Цувралын зураг</label>
        <input
          id="image"
          name="image"
          ref="fileRef"
          @change="handleFileChange"
          type="file"
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Цувралын зураг оруулна уу"
        />
      </div>

      <div class="flex space-x-4">
        <button
          type="button"
          @click="submitLesson"
          :disabled="isLoading"
          class="w-[250px] bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg transition duration-200 disabled:opacity-50"
        >
          {{ isLoading ? "Цуврал байршуулж байна..." : "Цуврал байршуулах" }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "LessonUploadForm",
  setup() {
    const store = useStore();

    const fileRef = ref(null);
    // Computed properties for data
    const chapters = computed(() => store.state.vod.chapters);
    const categories = computed(() => store.state.vod.categories);
    const isLoading = computed(() => store.state.vod.isLoading);
    const seriesForm = computed(() => store.state.vod.seriesForm);

    const handleSeriesForm = (e) => {
      store.commit("vod/setSeriesForm", {
        key: e.target.name,
        value: e.target.value,
      });
      console.log("series form", seriesForm.value);
    };

    const handleFileChange = (e) => {
      store.commit("vod/setSeriesForm", {
        key: e.target.name,
        value: e.target.files[0],
      });
      console.log("series form", seriesForm.value);
    };

    const submitLesson = () => {
      store.dispatch("vod/createSeries");
      fileRef.value.value = "";
    };

    return {
      fileRef,
      seriesForm,
      chapters,
      categories,
      isLoading,
      handleSeriesForm,
      handleFileChange,
      submitLesson,
    };
  },
};
</script>

<style scoped>
/* Optional: Add custom styles if needed */
</style>
