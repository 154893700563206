<template>
  <admin-container>
    <div class="flex space-x-4 border-b mb-4">
      <button v-for="component in components" :key="component.name" :class="tabClass(component.name)" @click="setActiveTab(component.name)" :aria-selected="activeTab === component.name">
        {{ component.title }}
      </button>
    </div>

    <div class="text-sm bg-white px-2">
      <component :is="currentComponent" />
    </div>
  </admin-container>
</template>

<script>
import { ref, computed } from "vue";
import AdminContainer from "@/pages/admin/AdminContainer.vue";
import NonSeriesList from "@/pages/admin/NonSeriesList.vue";
import SeriesList from "@/pages/admin/SeriesList.vue";

export default {
  name: "AdminVodList",
  components: {
    AdminContainer,
    NonSeriesList,
    SeriesList,
  },
  setup() {
    const isSeries = ref(false);

    const components = [
      { name: "video", title: "Бичлэг", component: NonSeriesList },
      { name: "series", title: "Цуврал", component: SeriesList },
    ];

    const activeTab = ref("video");

    const currentComponent = computed(() => {
      const comp = components.find((c) => c.name === activeTab.value);
      return comp ? comp.component : null;
    });

    const tabClass = (tab) =>
      `py-2 px-4 rounded-t-lg text-sm font-medium focus:outline-none ${
        activeTab.value === tab ? "text-blue-600 border-b-2 border-blue-600" : "text-gray-500 hover:text-gray-700 border-b-2 border-transparent"
      }`;

    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };

    const toggleIsSeries = (value) => {
      isSeries.value = value;
    };

    return {
      isSeries,
      components,
      activeTab,
      currentComponent,
      tabClass,
      setActiveTab,
      toggleIsSeries,
    };
  },
};
</script>
