<template>
  <div>
    <!-- Filters -->
    <div class="grid grid-cols-1 md:grid-cols-10 gap-4 mb-4">
      <!-- Series Filter -->
      <div class="col-span-1 md:col-span-2">
        <label for="series" class="block mb-2 text-sm font-medium text-gray-900">Цуврал</label>
        <select
          id="series"
          name="series"
          v-model="filterValues.seriesId"
          @change="onSeriesChange"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        >
          <option disabled value="">Цуврал сонгох</option>
          <option v-for="series in seriesList" :key="series.series_id" :value="series.series_id">
            {{ series.title }}
          </option>
        </select>
      </div>

      <!-- Season Filter -->
      <div class="col-span-1 md:col-span-2">
        <label for="season" class="block mb-2 text-sm font-medium text-gray-900">Улирал</label>
        <select
          id="season"
          name="season"
          v-model="filterValues.seasonId"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          :disabled="!seasonsList.length"
        >
          <option disabled value="">Улирал сонгох</option>
          <option v-for="season in seasonsList" :key="season.season_id" :value="season.season_id">
            {{ season.title || "Season " + season.season_number }}
          </option>
        </select>
      </div>

      <!-- Date Filter -->
      <div class="col-span-1 md:col-span-2">
        <label for="date" class="block mb-2 text-sm font-medium text-gray-900">Огноо</label>
        <input
          type="date"
          id="date"
          name="date"
          v-model="filterValues.date"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        />
      </div>

      <!-- Search Filter -->
      <div class="col-span-1 md:col-span-3 relative flex flex-col justify-center focus-within:text-blue-500">
        <label for="search" class="block mb-2 text-sm font-medium text-gray-900">Хайх</label>
        <input
          type="text"
          id="search"
          name="search"
          placeholder="Анги нэр"
          v-model="filterValues.search"
          @keyup.enter="fetchEpisodeList"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        />
        <button type="button" class="absolute right-0 top-[40px] mr-2.5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" @click="fetchEpisodeList">
          <!-- SVG for Search Icon -->
        </button>
      </div>

      <!-- Reset Filters Button -->
      <div class="col-span-1 md:col-span-1 flex items-end">
        <button
          type="button"
          @click="clearFilters"
          class="w-10 h-10 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center justify-center"
        >
          <font-awesome-icon :icon="['fas', 'xmark']" />
        </button>
      </div>
    </div>

    <!-- Episodes Table -->
    <div class="mt-4" v-if="!isLoading && episodeList.length > 0">
      <hot-table :settings="hotSettings" :data="episodeList" ref="hotRef" />
    </div>
    <div class="mt-4" v-if="!isLoading && episodeList.length === 0">
      <p class="text-center text-gray-500">Анги олдсонгүй</p>
    </div>

    <div class="fixed inset-0 bg-black bg-opacity-50 z-[9998]" v-if="isVideoModalOpen" @click="isVideoModalOpen = false"></div>

    <div class="fixed inset-0 bg-black bg-opacity-50 z-[9998]" v-if="isEditModalOpen" @click.self="closeEditModal"></div>
    <div class="fixed inset-0 flex items-center justify-center z-[9999]" v-if="isEditModalOpen">
      <div class="bg-white p-6 rounded-lg w-full max-w-md mx-auto">
        <h2 class="text-xl font-semibold mb-4">Анги засах</h2>
        <form @submit.prevent="updateEpisode">
          <div class="mb-4">
            <label for="title" class="block text-sm font-medium text-gray-700">Гарчиг</label>
            <input type="text" id="title" v-model="editForm.title" class="mt-1 block w-full border border-gray-300 rounded-md p-2" required />
          </div>

          <div class="mb-4">
            <label for="description" class="block text-sm font-medium text-gray-700">Тайлбар</label>
            <textarea id="description" v-model="editForm.description" class="mt-1 block w-full border border-gray-300 rounded-md p-2" required></textarea>
          </div>

          <!-- You can add more fields here as needed -->

          <div class="mb-4">
            <label for="video" class="block text-sm font-medium text-gray-700">Бичлэг солих</label>
            <input type="file" id="video" ref="videoFileRef" accept="video/*" class="mt-1 block w-full" />
          </div>

          <div class="flex justify-end">
            <button type="button" @click="closeEditModal" class="mr-2 px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600">Болих</button>
            <button type="submit" class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">Хадгалах</button>
          </div>
        </form>
      </div>
    </div>

    <div
      class="absolute top-[30%] l-4 r-4 md:fixed md:top-1/2 md:left-1/2 transform md:-translate-x-1/2 md:-translate-y-1/2 overflow-hidden transition-all duration-300 ease-in-out flex flex-col items-center justify-center z-[9999]"
      v-if="isVideoModalOpen"
    >
      <video-player :episodeId="episodeId" />
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";

import VideoPlayer from "@/components/VideoPlayer.vue";
import { adminInstance } from "@/lib/adminInstance";

registerAllModules();

export default {
  name: "SeriesEpisodeList",
  components: {
    HotTable,
    VideoPlayer,
  },
  setup() {
    const store = useStore();
    const isLoading = computed(() => store.state.admin.isLoading);
    const episodeList = computed(() => store.state.admin.episodeList);
    const categories = computed(() => store.state.vod.categories);

    const seriesList = ref([]);
    const seasonsList = ref([]);

    const hotRef = ref(null);

    const isVideoModalOpen = ref(false);
    const episodeId = ref(null);

    const filterValues = reactive({
      seriesId: "",
      seasonId: "",
      date: "",
      search: "",
    });

    const fetchSeriesList = async () => {
      try {
        const response = await adminInstance.get("/series");
        if (response.status === 200) {
          seriesList.value = response.data.series;
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchSeasonsList = async () => {
      if (!filterValues.seriesId) {
        seasonsList.value = [];
        return;
      }
      try {
        const response = await adminInstance.get(`/series/${filterValues.seriesId}/seasons`);
        if (response.status === 200) {
          seasonsList.value = response.data.seasons;
        }
      } catch (error) {
        console.error(error);
      }
    };

    const onSeriesChange = () => {
      filterValues.seasonId = "";
      fetchSeasonsList();
      fetchEpisodeList();
      store.dispatch("vod/fetchCategories");
    };

    const fetchEpisodeList = async () => {
      await store.dispatch("admin/fetchEpisodeList", filterValues);
    };

    onMounted(() => {
      fetchSeriesList();
      fetchEpisodeList();
    });

    watch(
      () => ({ ...filterValues }),
      () => {
        fetchEpisodeList();
      },
      { deep: true }
    );

    const actionRenderer = (instance, td, row) => {
      td.classList.add("htMiddle");
      const watchButtonId = `watch-button-${row}`;
      const deleteButtonId = `delete-button-${row}`;

      td.innerHTML = `<div class="flex justify-center items-center gap-2 py-1">
        <button id="${watchButtonId}" class="w-28 bg-blue-500 text-white rounded-md text-center p-1 hover:bg-blue-600 transition ease-in-out duration-150">Үзэх</button>
        <button id="${deleteButtonId}" class="w-28 bg-red-500 text-white rounded-md text-center p-1 hover:bg-red-600 transition ease-in-out duration-150">Устгах</button>
      </div>`;

      const watchButton = document.getElementById(watchButtonId);
      const deleteButton = document.getElementById(deleteButtonId);
      const episodeIdValue = instance.getDataAtRowProp(row, "episode_id");

      if (watchButton) {
        watchButton.onclick = () => {
          isVideoModalOpen.value = true;
          episodeId.value = episodeIdValue;
        };
      }
      if (deleteButton) {
        deleteButton.onclick = async () => {
          try {
            const response = await adminInstance.delete(`/episodes/${episodeIdValue}`);
            if (response.status === 200) {
              instance.alter("remove_row", row);
            }
          } catch (error) {
            console.error(error);
          }
        };
      }
    };

    // Handsontable settings
    const hotSettings = {
      data: episodeList.value,
      colWidths: [null, 200, 200, 200, 200, 100, 200, 200, 200, 200, 200, 200],
      colHeaders: ["ID", "Анги нэр", "Цуврал", "Улирал", "Тайлбар", "Бичлэгийн урт (мин)", "Файл нэр", "Файл төрөл", "Хэмжээ (MB)", "Оруулсан огноо", "Шинэчлэгдсэн огноо", "Үйлдэл"],
      columns: [
        { data: "episode_id" },
        { data: "title", readOnly: true },
        { data: "series_title", readOnly: true },
        { data: "season_title", readOnly: true },
        { data: "description", readOnly: true },
        { data: "duration", readOnly: true },
        { data: "filename", readOnly: true },
        { data: "file_type", readOnly: true },
        { data: "file_size", readOnly: true },
        { data: "created_at", readOnly: true },
        { data: "updated_at", readOnly: true },
        {
          renderer: actionRenderer,
          readOnly: true,
        },
      ],
      hiddenColumns: {
        columns: [0],
        indicators: false,
      },
      fixedColumnsLeft: window.innerWidth < 768 ? 0 : 2,
      width: "100%",
      height: "576px",
      rowHeaders: true,
      columnSorting: true,
      manualRowResize: true,
      manualColumnResize: true,
      manualRowMove: true,
      manualColumnMove: true,
      contextMenu: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    // Clear all filters
    const clearFilters = () => {
      filterValues.seriesId = "";
      filterValues.seasonId = "";
      filterValues.date = "";
      filterValues.search = "";
      fetchEpisodeList();
    };

    return {
      isLoading,
      episodeList,
      categories,
      hotRef,
      hotSettings,
      filterValues,
      seriesList,
      seasonsList,
      isVideoModalOpen,
      episodeId,
      onSeriesChange,
      clearFilters,
    };
  },
};
</script>
