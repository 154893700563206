<template>
  <div class="sm:hidden bg-gray-800">
    <button type="button" class="inline-flex items-center p-2 text-sm text-white rounded-lg hover:bg-white focus:outline-none focus:ring-2 focus:ring-gray-200" @click="toggleSidebar">
      <span class="sr-only">Open sidebar</span>
      <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path
          clip-rule="evenodd"
          fill-rule="evenodd"
          d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
        ></path>
      </svg>
    </button>
  </div>

  <div class="flex overflow-x-hidden">
    <aside
      id="default-sidebar"
      class="fixed top-0 left-0 z-[9999] w-64 h-screen bg-gray-800 border-gray-700 transition-transform -translate-x-full sm:translate-x-0"
      :class="{ 'translate-x-0': isSidebarOpen }"
      aria-label="Sidenav"
    >
      <div class="overflow-y-auto py-5 px-3 h-full bg-gray-800 border-gray-700 border-r">
        <ul class="space-y-2">
          <div class="w-32 mx-auto mb-4">
            <img src="../../assets//logo1.png" alt="" class="w-full h-full" />
          </div>
          <router-link
            to="/admin/dashboard"
            class="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-gray-700 group cursor-pointer"
            :class="{ 'bg-gray-700': path == '/admin/dashboard' }"
          >
            <font-awesome-icon icon="dashboard" class="w-6 h-6 text-gray-400 transition duration-75 group-hover:text-white" :class="{ 'text-white': path == '/admin/video' }" />
            <span class="ml-3">Хянах самбар</span>
          </router-link>
          <router-link
            to="/admin/tv-archive"
            class="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-gray-700 group cursor-pointer"
            :class="{ 'bg-gray-700': path == '/admin/tv-archive' }"
          >
            <font-awesome-icon icon="tv" class="w-6 h-6 text-gray-400 transition duration-75 group-hover:text-white" :class="{ 'text-white': path == '/admin/tv-archive' }" />
            <span class="ml-3">TВ архив</span>
          </router-link>
          <!-- <router-link
            to="/admin/video/upload"
            class="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-gray-700 group cursor-pointer"
            :class="{ 'bg-gray-700': path == '/admin/video/upload' }"
          >
            <font-awesome-icon icon="upload" class="w-6 h-6 text-gray-400 transition duration-75 group-hover:text-white" :class="{ 'text-white': path == '/admin/upload' }" />
            <span class="ml-3">Бичлэг оруулах</span>
          </router-link> -->
          <router-link
            to="/admin/upload"
            class="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-gray-700 group cursor-pointer"
            :class="{ 'bg-gray-700': path == '/admin/video/upload' }"
          >
            <font-awesome-icon icon="upload" class="w-6 h-6 text-gray-400 transition duration-75 group-hover:text-white" :class="{ 'text-white': path == '/admin/upload' }" />
            <span class="ml-3">Бичлэг оруулах</span>
          </router-link>
          <router-link
            to="/admin/vod-list"
            class="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-gray-700 group cursor-pointer"
            :class="{ 'bg-gray-700': path == '/admin/vod-list' }"
          >
            <font-awesome-icon icon="list" class="w-6 h-6 text-gray-400 transition duration-75 group-hover:text-white" :class="{ 'text-white': path == '/admin/vod-list' }" />
            <span class="ml-3">VOD жагсаалт</span>
          </router-link>
          <div class="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-gray-700 group cursor-pointer" @click="logout">
            <font-awesome-icon icon="sign-out-alt" class="w-6 h-6 text-gray-400 transition duration-75 group-hover:text-white" />
            <span class="ml-3">Гарах</span>
          </div>
        </ul>
      </div>
    </aside>
    <div class="relative flex-1 md:ml-64 overflow-y-scroll p-4">
      <loading-animation v-if="isLoading" />
      <error-modal v-if="error" :error="error" />
      <success-modal v-if="success" :success="success" />
      <slot></slot>
    </div>
  </div>

  <div v-if="isSidebarOpen" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9998]" @click="isSidebarOpen = false">
    <button class="fixed top-0 right-0 p-4 text-white" @click="isSidebarOpen = false">
      <font-awesome-icon icon="times" class="w-6 h-6" />
    </button>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import LoadingAnimation from "@/components/ui/LoadingAnimation.vue";
import ErrorModal from "@/components/ui/ErrorModal.vue";
import SuccessModal from "@/components/ui/SuccessModal.vue";
import { authInstance } from "@/lib/adminInstance";
export default {
  components: {
    LoadingAnimation,
    ErrorModal,
    SuccessModal,
  },
  setup() {
    const store = useStore();
    const isLoading = computed(() => store.state.admin.isLoading);
    const error = computed(() => store.state.admin.error);
    const success = computed(() => store.state.admin.success);

    const route = useRoute();
    const router = useRouter();
    const path = ref(route.path);
    const isSidebarOpen = ref(false);

    const toggleSidebar = () => {
      isSidebarOpen.value = !isSidebarOpen.value;
    };

    const logout = async () => {
      try {
        const response = await authInstance.get("/logout");

        if (response.status === 200) {
          router.push("/login");
        }
      } catch (error) {
        store.commit("admin/error", error.response.data.message);
      }
    };

    return {
      path,
      isLoading,
      error,
      success,
      isSidebarOpen,
      toggleSidebar,
      logout,
    };
  },
};
</script>
