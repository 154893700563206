import axios from "axios";

let adminBaseURL;
if (process.env.NODE_ENV === "production") {
  adminBaseURL = "https://app.mnb.mn/api/admin";
} else {
  adminBaseURL = "http://localhost:3002/api/admin";
}

export const adminInstance = axios.create({
  baseURL: adminBaseURL,
  withCredentials: true,
});

let authBaseURL;
if (process.env.NODE_ENV === "production") {
  authBaseURL = "https://app.mnb.mn/api/auth";
} else {
  authBaseURL = "http://localhost:3002/api/auth";
}

export const authInstance = axios.create({
  baseURL: authBaseURL,
  withCredentials: true,
});
